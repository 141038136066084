import { stat } from "fs";

import {
  IConstant,
  IFunction,
  ILink,
  ILoop,
  IM3API,
  IMap,
  IMeta,
  IMetaSchema,
  IParameter,
  ISchemaRecord,
  ISequence,
  IVariable,
} from "../types/Map";

export default class MapParserService {
  public static parse(data: any): IMap {
    if (!data?.DatabaseData) {
      throw new Error("Unable to parse DatabaseData");
    }
    const Meta = this.parseMeta(data);
    const Format = this.parseFormat(data);
    const SchemaIn = this.parseMetaSchemaIn(data);
    const SchemaOut = this.parseMetaSchemaOut(data);
    const Constants = this.parseConstants(data);
    const Variables = this.parseVariables(data);
    const M3APIs = this.parseM3APIs(data);
    const Sequences = this.parseSequences(data);

    const map: IMap = {
      Meta,
      Format,
      SchemaIn,
      SchemaOut,
      Constants,
      Variables,
      M3APIs,
      Sequences,
    };

    const stats = this.countUsages(map);
    map.Constants?.forEach((c) => {
      c.LinksCount = stats.get(c.ID) ?? 0;
    });
    map.Variables?.forEach((v) => {
      v.LinksCount = stats.get(v.ID) ?? 0;
    });
    map.M3APIs?.forEach((a) => {
      a.LinksCount = stats.get(a.Path) ?? 0;
    });
    return map;
  }

  private static parseMeta(data: any): IMeta {
    const mappingMeta = data?.DatabaseData?.MappingMeta;
    if (!mappingMeta) {
      throw new Error("Unable to parse MappingMeta");
    }
    let meta: IMeta = {
      ID: mappingMeta.ID,
      File: mappingMeta.File,
      Name: mappingMeta.Name,
      Standard: mappingMeta.Standard,
      Namespace: mappingMeta.Namespace,
      Version: mappingMeta.Version,
      Description: mappingMeta.Description,
      Layout: mappingMeta.Layout,
      ChangeDate: mappingMeta.ChangeDate,
    };

    return meta;
  }
  private static parseFormat(data: any) {
    const format = data?.DatabaseData?.Format;
    if (!format) {
      throw new Error("Unable to parse Format");
    }
    return format;
  }

  private static parseMetaSchemaIn(data: any) {
    const mappingMeta = data?.DatabaseData?.MappingMeta;
    if (!mappingMeta) {
      throw new Error("Unable to parse MappingMeta");
    }
    const schemaIn = mappingMeta?.SchemaIn;
    if (!schemaIn) {
      throw new Error("Unable to parse SchemaIn");
    }
    let schema: IMetaSchema = {
      ID: schemaIn.ID,
      File: schemaIn.File,
      Name: schemaIn.Name,
      Namespace: schemaIn.Namepsace,
      Category: schemaIn.Category,
      Version: schemaIn.Version,
    };
    return schema;
  }

  private static parseMetaSchemaOut(data: any) {
    const mappingMeta = data?.DatabaseData?.MappingMeta;
    if (!mappingMeta) {
      throw new Error("Unable to parse MappingMeta");
    }
    const schemaOut = mappingMeta?.SchemaOut;
    if (!schemaOut) {
      return undefined;
    }
    let schema: IMetaSchema = {
      ID: schemaOut.ID,
      File: schemaOut.File,
      Name: schemaOut.Name,
      Namespace: schemaOut.Namepsace,
      Category: schemaOut.Category,
      Version: schemaOut.Version,
    };
    return schema;
  }

  private static parseConstants(data: any) {
    let arr = this.getObjArr(data.DatabaseData?.Constants?.Constant);
    let constants: IConstant[] = arr
      .map((c: any) => ({
        ID: c?.ID_F?._ID,
        Name: c.Name,
        Description:
          c.Description === "Please describe me" ? "" : c.Description,
        Type: c.Type,
        Value: c.Value,
      }))
      .sort((a: any, b: any) => (a.Name > b.Name ? 1 : -1));

    return constants;
  }

  private static parseVariables(data: any) {
    let arr = this.getObjArr(data.DatabaseData?.Variables?.Variable);
    let variables: IVariable[] = arr
      .map((v: any) => ({
        ID: v?.ID_V?._ID,
        Name: v.Name,
        Description:
          v.Description === "Please describe me" ? "" : v.Description,
        Type: v.Type,
        InitialValue: v.InitialValue,
      }))
      .sort((a: any, b: any) => (a.Name > b.Name ? 1 : -1));

    return variables;
  }

  private static parseSchemaIn(data: any) {
    let records = this.getObjArr(data.DatabaseData?.SchemaIn?.Record);
    let schema: ISchemaRecord[] = records
      .map((r: any) => ({
        ID: r?.ID_M._ID,
        Name: r.PATH,
        Description: r.PATH,
        Path: r.PATH,
        Type: r.Type,
      }))
      .sort((a: any, b: any) => (a.Path > b.Path ? 1 : -1));

    return schema;
  }

  private static parseSchemaOut(data: any) {
    let records = this.getObjArr(data.DatabaseData?.SchemaOut?.Record);
    let schema: ISchemaRecord[] = records
      .map((r: any) => ({
        ID: r?.ID_M._ID,
        Name: r.PATH,
        Description: r.PATH,
        Path: r.PATH,
        Type: r.Type,
      }))
      .sort((a: any, b: any) => (a.Path > b.Path ? 1 : -1));

    return schema;
  }

  private static parseM3APIs(data: any) {
    let fns = this.getObjArr(data.DatabaseData?.Functions?.Function);
    if (!fns) {
      return undefined;
    }
    const idx = fns
      .filter((f: any) => f.Type.startsWith("AV") || f.Type.startsWith("AR"))
      .reduce((idx: any, a: any) => {
        const Path = a.PATH;
        let els = a.PATH.split("/");
        idx[Path] = {
          Path,
          Program: els[1],
          Transaction: els[2],
        };
        return idx;
      }, {});
    const m3Apis: IM3API[] = Object.keys(idx)
      .sort()
      .map((key) => idx[key]);

    return m3Apis;
  }

  private static indexLoops(data: any) {
    let loops = this.getObjArr(data.DatabaseData?.Loops?.Loop);
    if (!loops) {
      return undefined;
    }
    let idx = loops.reduce((idx: any, cur: any) => {
      const ID = cur?.ID_L?._ID;
      const loop: ILoop = {
        ID,
        Name: cur.Name,
        Description:
          cur.Description === "Please describe me" ? "" : cur.Description,
        Condition: cur.Condition,
        ConditionDescription: codes[cur.Condition],
      };
      idx[ID] = loop;
      return idx;
    }, {});
    return idx;
  }

  private static indexImplementations(data: any) {
    let implementations = this.getObjArr(
      data.DatabaseData?.Implementations?.Implementation
    );
    if (!implementations) {
      return undefined;
    }
    let idx = implementations.reduce((idx: any, cur: any) => {
      const ID = cur?.Language?._IDREF;
      const code = cur?._cdata
        ?.replaceAll("    ", "\t")
        .split("\n")
        .map((l: string) =>
          l.startsWith("\t\t") ? l.substring(2, l.length) : l
        )
        .join("\n")
        .replaceAll("\t", "  ")
        .replaceAll("// Please implement me\n", "");
      idx[ID] = code;
      return idx;
    }, {});
    return idx;
  }

  private static indexParameters(data: any): IParameter[] {
    let parameters = this.getObjArr(data.DatabaseData?.Parameters?.Parameter);
    if (!parameters) {
      return [];
    }
    return parameters.reduce((idx: any, cur: any) => {
      const ID = cur?.ID_P?._ID;
      const parameter: IParameter = {
        ID,
        Name: cur.Name,
        Description:
          cur.Description === "Please describe me" ? "" : cur.Description,
        Position: cur.Position,
        Type: cur.Type,
        FunctionID: cur?.ID_F?._IDREF,
      };
      idx[ID] = parameter;
      return idx;
    }, {});
  }

  private static indexFunctions(data: any) {
    const implementations = this.indexImplementations(data);
    let functions = this.getObjArr(data.DatabaseData?.Functions?.Function);
    if (!functions) {
      return undefined;
    }
    let idx = functions.reduce((idx: any, cur: any) => {
      const ID = cur?.ID_F?._ID;
      const Implementation = implementations[ID];
      const fn: IFunction = {
        ID,
        Name: cur.Name,
        Description:
          cur.Description === "Please describe me" ? "" : cur.Description,
        Implementation,
        Type: cur.Type,
        TypeDescription: codes[cur.Type],
        Path: cur.PATH,
      };
      idx[ID] = fn;
      return idx;
    }, {});
    return idx;
  }

  private static parseLinks(data: any): ILink[] {
    const vars = this.parseVariables(data);
    const consts = this.parseConstants(data);
    const schemaIn = this.parseSchemaIn(data);
    const schemaOut = this.parseSchemaOut(data);
    const parmsIdx = this.indexParameters(data);
    const loopsIdx = this.indexLoops(data);
    const fnsIdx = this.indexFunctions(data);

    const links = this.getObjArr(data?.DatabaseData?.Links?.Link);

    return links.map((l: any) => {
      const Type = l.Type;

      const inID = l?.InFrom?._IDREF;
      const outID = l?.OutTo?._IDREF;

      let fromRef, toRef;

      if (Type.startsWith("M")) {
        fromRef = schemaIn.find((s) => s.ID === inID);
      } else if (Type.startsWith("V")) {
        fromRef = vars.find((v) => v.ID === inID);
      } else if (Type.startsWith("K")) {
        fromRef = consts.find((c) => c.ID === inID);
      } else if (Type.startsWith("L")) {
        fromRef = loopsIdx[inID];
      } else if (Type.startsWith("P")) {
        fromRef = parmsIdx[inID];
      } else if (Type.startsWith("F")) {
        fromRef = fnsIdx[inID];
      }

      if (Type.endsWith("M")) {
        toRef = schemaOut.find((s) => s.ID === outID);
      } else if (Type.endsWith("V")) {
        toRef = vars.find((v) => v.ID === outID);
      } else if (Type.endsWith("L")) {
        toRef = loopsIdx[outID];
      } else if (Type.endsWith("P")) {
        toRef = parmsIdx[outID];
      } else if (Type.endsWith("F")) {
        toRef = fnsIdx[outID];
      }

      let SequenceID = "";
      const inbound = l.SequenceIn === -1;

      if (inbound) {
        if (Type.endsWith("P")) {
          SequenceID = toRef.FunctionID;
        } else if (Type.endsWith("L") || Type.endsWith("F")) {
          SequenceID = toRef.ID;
        }
      } else {
        if (Type.startsWith("P")) {
          SequenceID = fromRef.FunctionID;
        } else if (Type.startsWith("L") || Type.startsWith("F")) {
          SequenceID = fromRef.ID;
        }
      }

      const link: ILink = {
        SequenceID,
        LinkType: Type,
        Direction: inbound ? "In" : "Out",
        From: fromRef,
        To: toRef,
      };

      return link;
    });
  }

  private static parseSequences(data: any) {
    const links = this.parseLinks(data);

    let seqs = this.getObjArr(data.DatabaseData?.SequenceList?.Sequence);
    if (!seqs) {
      throw new Error("Unable to parse SequenceList");
    }

    const loops = this.indexLoops(data);
    const functions = this.indexFunctions(data);
    const apis = this.parseM3APIs(data);
    let indentLevel = 0;

    const sequences: ISequence[] = seqs.map((s: any) => {
      const Type = s.Type;
      const ID = s.ID_S._IDREF;
      const inLinks = links.filter(
        (l) => l.SequenceID === ID && l.Direction === "In"
      );
      const outLinks = links.filter(
        (l) => l.SequenceID === ID && l.Direction === "Out"
      );

      switch (Type) {
        case "LB": {
          const loop = loops[ID];
          let seq: ISequence = {
            ID,
            Name: loop.Name,
            Description: loop.Description,
            Number: s.Number,
            Type,
            Level: indentLevel,
            TypeDescription: codes[s.Type],
            Condition: loop.Condition,
            ConditionDescription: loop.ConditionDescription,
            InputLinks: inLinks,
            OutputLinks: outLinks,
          };
          indentLevel++;
          return seq;
        }
        case "LE": {
          const loop = loops[ID];
          indentLevel--;
          let seq: ISequence = {
            ID,
            Name: loop.Name,
            Description:
              loop.Description === "Please describe me" ? "" : loop.Description,
            Number: s.Number,
            Type,
            Level: indentLevel,
            TypeDescription: codes[s.Type],
            Condition: loop.Condition,
            ConditionDescription: loop.ConditionDescription,
          };

          return seq;
        }
        case "F": {
          const fn: IFunction = functions[ID];
          const api = apis?.find((api) => api.Path === fn.Path);
          const seq: ISequence = {
            ID,
            Name: fn.Name,
            Description: fn.Description,
            Number: s.Number,
            Type: fn.Type,
            Level: indentLevel,
            TypeDescription: fn.TypeDescription,
            Condition: "",
            ConditionDescription: "",
            InputLinks: inLinks,
            Code: fn.Implementation,
            M3API: api,
            OutputLinks: outLinks,
          };
          return seq;
        }
        default: {
          throw new Error(`Sequence type ${Type} not expected`);
        }
      }
    });

    return sequences;
  }

  private static countUsages(map: IMap) {
    const stats = new Map<string, number>();
    map.Sequences?.forEach((s) => {
      if (s.M3API) {
        let key = s.M3API.Path;
        let count = (stats.get(key) ?? 0) + 1;
        stats.set(key, count);
      }
      s.InputLinks?.forEach((l) => {
        let key = l.From.ID;
        let count = (stats.get(key) ?? 0) + 1;
        stats.set(key, count);
      });
      s.OutputLinks?.forEach((l) => {
        let key = l.To.ID;
        let count = (stats.get(key) ?? 0) + 1;
        stats.set(key, count);
      });
    });
    return stats;
  }

  private static getObjArr(obj: any) {
    let arr: any[] = [];
    if (obj !== undefined) {
      if (!Array.isArray(obj)) {
        arr = [obj];
      } else {
        arr = obj;
      }
    }
    return arr;
  }
}

const codes: any = {
  AS: "M3 Settings",
  UV: "User Function",
  UB: "User Boolean",
  AVM: "Exit map on M3 NOK",
  AVI: "Ingore M3 NOK",
  ARM: "Exit map on M3 NOK",
  ARI: "Ignore M3 NOK",
  ARL: "Exit loop on M3 NOK",
  F: "Function",
  LB: "Loop Begin",
  LE: "Loop End",
  WT: "While true",
  WF: "While false",
  IT: "If true",
  IF: "If false",
};

import Box from "@mui/material/Box/Box";
import * as React from "react";
import Highlight from "react-highlight";
import Markdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import remarkGfm from "remark-gfm";

import FormattedOutput from "../components/FormattedOutput";
import OutputToolbar from "../components/OutputToolbar";
import { MapContext } from "../context/MapContext";
import MarkdownRenderService from "../services/MarkdownRenderService";
import PrintPage from "../components/print/PrintPage";

interface OutputPageProps {}

const OutputPage: React.FC<OutputPageProps> = () => {
  const [view, setView] = React.useState("fmt");
  const { map } = React.useContext(MapContext);
  const navigate = useNavigate();
  React.useEffect(() => {
    if (!map) {
      navigate("/");
    } else {
      document.title = map.Meta.Name;
    }
  }, [map, navigate]);

  if (!map) {
    return <></>;
  }

  const md = MarkdownRenderService.render(map);

  return (
    <>
      <PrintPage>
        <Box className="no-print">
          <OutputToolbar map={map} view={view} setView={setView} />
        </Box>
        <Box sx={{ fontSize: "12px", lineHeight: ".95em" }}>
          {view === "fmt" && <FormattedOutput map={map} />}
          {view === "gfm" && (
            <div className="gfm">
              <Markdown
                remarkPlugins={[remarkGfm]}
                components={{
                  code({ children }) {
                    return <Highlight className="Java">{children}</Highlight>;
                  },
                }}
              >
                {md}
              </Markdown>
            </div>
          )}
          {view === "md" && <pre className="md">{md}</pre>}
          {view === "json" && (
            <pre className="json">{JSON.stringify(map, null, 2)}</pre>
          )}
        </Box>
      </PrintPage>
    </>
  );
};

export default OutputPage;

import { Box, Grid, Stack, Typography } from "@mui/material";
import * as React from "react";
import Highlight from "react-highlight";
import { ISequence } from "../../types/Map";
import Parameters from "./Parameters";

interface SequenceProps {
  sequence: ISequence;
}

const Sequence: React.FC<SequenceProps> = ({ sequence: s }) => {
  const indent = s.Level * 2;
  return (
    <Box marginBottom={2} className="mapping-sequence">
      
        <Grid container>
          <Grid item xs={3}></Grid>
          <Grid item xs={6} paddingLeft={indent}>
            {s.Type === "LB" && (
              <Box
                sx={{
                  height: "7px",
                  width: "100%",
                  backgroundColor: colors[s.Level],
                }}
              ></Box>
            )}

            {s.Type === "LB" && (
              <Stack direction="row">
                <Typography
                  variant="h3"
                  fontSize="medium"
                  fontWeight="bold"
                  sx={{ flexGrow: 1 }}
                >
                  {s.Name}
                </Typography>
                <Typography variant="h3" fontSize="small">
                  {s.ConditionDescription}
                </Typography>
              </Stack>
            )}

            {s.Type !== "LB" && s.Type !== "LE" && (
              <Stack direction="row">
                <Typography
                  variant="h3"
                  fontSize="medium"
                  fontWeight="bold"
                  sx={{ flexGrow: 1 }}
                >
                  {s.Name}
                </Typography>
                <Typography variant="h3" fontSize="small">
                  {s.TypeDescription}
                </Typography>
              </Stack>
            )}

            {s.Type === "LE" && (
              <>
                <Typography variant="h3" fontSize="small">
                  End {s.Name}
                </Typography>
                <Box
                  sx={{
                    height: "7px",
                    width: "100%",
                    backgroundColor: colors[s.Level],
                  }}
                ></Box>
              </>
            )}
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
        {s.Type !== "LE" && (
          <Grid container>
            <Grid item xs={3} paddingTop={.5}>
              {s.InputLinks && s.InputLinks?.length > 0 && (
                <Parameters links={s.InputLinks} />
              )}
            </Grid>

            <Grid item xs={6} paddingLeft={indent}>
              {s.M3API && <Typography>{s.M3API.Path}</Typography>}
              {s.Code && (
                <Box fontSize={11}>
                  <Highlight className="Java">{s.Code}</Highlight>
                </Box>
              )}
            </Grid>

            <Grid item xs={3} paddingTop={.5}>
              {s.OutputLinks && s.OutputLinks?.length > 0 && (
                <Parameters links={s.OutputLinks} />
              )}
            </Grid>
          </Grid>
        )}

    </Box>
  );
};

export default Sequence;

const colors = ["#2a3eb1", "#14a37f", "#b2102f", "#b2a300", "#4615b2"];

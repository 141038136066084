import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Layout from "./components/Layout";
import { MapContextProvider } from "./context/MapContext";
import ErrorPage from "./pages/ErrorPage";
import OutputPage from "./pages/OutputPage";
import UploadPage from "./pages/UploadPage";
import MapParserService from "./services/MapParserService";

export const svc = new MapParserService()

function App() {

  const router = createBrowserRouter([
    {
      id: "root",
      path: "/",
      Component: Layout,
      children: [
        {
          index: true,
          Component: UploadPage,
        },
        {
          path: "/output",
          Component: OutputPage,
        },
        
      ],
      ErrorBoundary: ErrorPage,
    },
  ]);

  return (
    <MapContextProvider>
      <RouterProvider
        router={router}
        fallbackElement={<div>Initial Load...</div>}
      />
    </MapContextProvider>
  );
}

export default App;

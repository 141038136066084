import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import * as React from "react";

interface ViewSelectProps {
  value: string
  setValue: (value: string) => void
}

const ViewSelect: React.FunctionComponent<ViewSelectProps> = ({value, setValue}) => {
  

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl>
      {/* <FormLabel id="demo-controlled-radio-buttons-group">View</FormLabel> */}
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel value="fmt" control={<Radio />} label="Formatted" />
        <FormControlLabel value="gfm" control={<Radio />} label="Markdown" />
        <FormControlLabel value="md" control={<Radio />} label="Markdown raw" />
        <FormControlLabel value="json" control={<Radio />} label="JSON" />
      </RadioGroup>
    </FormControl>
  );
};

export default ViewSelect;

import * as React from "react";
import { ContentCopy } from "@mui/icons-material";
import Button from "@mui/material/Button/Button";


interface DownloadButtonProps {
  content: string
}

const DownloadButton: React.FunctionComponent<
  DownloadButtonProps
> = ({content}: DownloadButtonProps) => {
  const [allowed, setAllowed] = React.useState(false);
  React.useEffect(() => {
    //@ts-ignore
    const d: PermissionDescriptor = { name: "clipboard-write" };
    navigator.permissions.query(d).then((result) => {
      if (result.state === "granted") {
        setAllowed(true);
      }
    });
  }, [allowed]);

  if (!allowed) {
    return <></>;
  }
  return (
    <Button
      startIcon={<ContentCopy />}
      variant="outlined"
      color="inherit"
      onClick={() => {
        navigator.clipboard.writeText(content)
      }}
    >
      Copy
    </Button>
  );
};

export default DownloadButton;

import * as React from "react";
import { Download } from "@mui/icons-material";
import Button from "@mui/material/Button/Button";
import { saveAs } from "file-saver";

interface DownloadButtonProps {
  content: string;
  fileName: string;
}

const DownloadButton: React.FunctionComponent<DownloadButtonProps> = ({
  content,
  fileName
}: DownloadButtonProps) => {
  return (
    <Button
      startIcon={<Download />}
      variant="outlined"
      color="inherit"
      onClick={() => {
        const file = new Blob([content], {
          type: "text/plain;charset=utf-8",
        });
        saveAs(file, fileName);
      }}
    >
      Download
    </Button>
  );
};

export default DownloadButton;

import * as React from "react";
import { IMap } from "../types/Map";

interface IMapContext {
  map: IMap | null;
  setMap: (map: IMap | null) => void;
}
const defaultValue: IMapContext = {
  map: null,
  setMap: () => {},
};
export const MapContext = React.createContext<IMapContext>(defaultValue);

export const MapContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [map, setMap] = React.useState<IMap | null>(null);
  return (
    <MapContext.Provider value={{ map, setMap }}>
      {children}
    </MapContext.Provider>
  );
};

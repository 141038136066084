import { Close, FolderZip, UploadFileOutlined } from "@mui/icons-material";
import { Box, Container, IconButton, LinearProgress, Paper, Snackbar, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import byteSize from "byte-size";
import * as React from "react";
import { Accept, useDropzone } from "react-dropzone";

interface UploadFileProps {
  onLoad: (data: any) => Promise<any>;
  label?: string;
  accept?: Accept;
}

const UploadFile: React.FunctionComponent<UploadFileProps> = ({
  onLoad,
  label,
  accept,
}) => {
  const [active, setActive] = React.useState(false);
  const [file, setFile] = React.useState<File | null>(null);
  const [error, setError] = React.useState("");

  const reset = () => {
    setFile(null)
    setError("")
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (files) => {
      const file = files[0];
      setFile(file);
      setActive(false);
      try {
        await onLoad(file);
        reset()
      } catch (err: any) {
        setError(err.toString());
      }
    },
    onDragEnter: () => {
      setActive(true);
    },
    onDragLeave: () => {
      setActive(false);
    },
    accept,
    multiple: false,
  });
  const { ref, ...rootProps } = getRootProps();
  const inputProps = getInputProps();

  function toStringFn(this: any) {
    return `${this.value} ${this.unit}`;
  }
  const fileSize = file ? byteSize(file.size, { toStringFn }).toString() : "";


  return (
    <Container maxWidth="sm">
      <Paper>
        <Box
          padding={4}
          onMouseOver={() => {
            setActive(true);
          }}
          onMouseOut={() => {
            setActive(false);
          }}
        >
          {label && <Typography>{label}</Typography>}
          <Box
            {...rootProps}
            ref={ref}
            sx={{
              backgroundColor: active ? grey[300] : grey[100],
              borderRadius: 2,
            }}
          >
            <input {...inputProps} />
            <Stack
              direction="column"
              padding={4}
              spacing={2}
              alignItems="center"
            >
              <UploadFileOutlined fontSize="large" />
              <Typography flexGrow="1">
                Drag and drop here or{" "}
                <Typography component="span"
                  sx={{
                    display: "inline",
                    textDecoration: "underline",
                    "&:hover": { cursor: "pointer" },
                  }}
                >
                  upload a file
                </Typography>
              </Typography>
            </Stack>
          </Box>
          {file && (
            <>
              <Stack
                direction="row"
                paddingTop={1}
                spacing={1}
                marginBottom={1}
              >
                <FolderZip />
                <Typography flexGrow={1}>{file.name}</Typography>
                <Typography>{fileSize}</Typography>
              </Stack>
              {!error && <LinearProgress />}
              {error && (
                <Snackbar
                  open={Boolean(error)}
                  anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                  onClose={reset}
                  message={error}
                  action={<>
                  <IconButton onClick={reset} color="inherit">
                  <Close/>
                  </IconButton>
                  </>}
                />
              )}
            </>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default UploadFile;

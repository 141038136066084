import { Print } from "@mui/icons-material";
import Button from "@mui/material/Button/Button";
import * as React from "react";

const DownloadButton: React.FunctionComponent = () => {
  return (
    <Button
      startIcon={<Print />}
      variant="outlined"
      color="inherit"
      onClick={() => {
        window.print();
      }}
    >
      Print
    </Button>
  );
};

export default DownloadButton;

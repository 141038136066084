import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, Paper } from "@mui/material";
import Box from "@mui/material/Box/Box";
import IconButton from "@mui/material/IconButton/IconButton";
import Stack from "@mui/material/Stack/Stack";
import Typography from "@mui/material/Typography/Typography";
import * as React from "react";

interface ExpandingSectionProps extends React.PropsWithChildren {
  title: string;
  expanded?: boolean;
  onChange: (value: boolean) => void;
}

const ExpandingSection: React.FunctionComponent<ExpandingSectionProps> = ({
  title,
  expanded,
  onChange,
  children,
}) => {
  return (
    <Box marginBottom={1} className="expanding-section">
      <Paper>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          paddingLeft={1}
          onClick={() => {
            onChange(!expanded);
          }}
        >
          <Typography component="h2" fontSize="medium">
            {title}
          </Typography>

          <IconButton>{expanded ? <ExpandLess /> : <ExpandMore />}</IconButton>
        </Stack>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </Paper>
    </Box>
  );
};

export default ExpandingSection;

import { Box, Container } from "@mui/material";
import * as React from "react";
import { Outlet } from "react-router-dom";

import Footer from "./Footer";
import Header from "./Header";

interface LayoutProps {}

const Layout: React.FunctionComponent<LayoutProps> = () => {
  return (
    <>
      <Box paddingBottom={2} className="no-print">
        <Header />
      </Box>
      <Container maxWidth="xl">
        <Outlet />
      </Container>
      <Footer />
    </>
  );
};

export default Layout;

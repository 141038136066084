import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import * as React from "react";

interface MetaProps {
  data: any;
}

const Meta: React.FC<MetaProps> = ({ data }) => {
  return (
    
      <TableContainer component={Paper}>
        <Table size="small">
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>{data.Name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Version</TableCell>
            <TableCell>{data.Version}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Schema In</TableCell>
            <TableCell>{data.SchemaIn?.Name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Schema Out</TableCell>
            <TableCell>{data.SchemaOut?.Name}</TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    
  );
};

export default Meta;

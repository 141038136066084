import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import * as React from "react";
import { IMap } from "../types/Map";
import ExpandingSection from "./ExpandingSection";
import Constants from "./map/Constants";
import M3APIs from "./map/M3Apis";
import Meta from "./map/Meta";
import Sequence from "./map/Sequence";
import Variables from "./map/Variables";

interface FormattedOutputProps {
  map: IMap;
}

const FormattedOutput: React.FunctionComponent<FormattedOutputProps> = ({
  map,
}) => {
  const [metaExpanded, setMetaExpanded] = React.useState(false);
  const [constsExpanded, setConstsExpanded] = React.useState(false);
  const [varsExpanded, setVarsExpanded] = React.useState(false);
  const [m3ApisExpanded, setM3ApisExpanded] = React.useState(false);
  const [seqsExpanded, setSeqsExpanded] = React.useState(true);

  const allChecked = () => {
    return (
      metaExpanded &&
      constsExpanded &&
      varsExpanded &&
      m3ApisExpanded &&
      seqsExpanded
    );
  };

  const someChecked = () => {
    return (
      metaExpanded ||
      constsExpanded ||
      varsExpanded ||
      m3ApisExpanded ||
      seqsExpanded
    );
  };

  return (
    <>
      <Box className="no-print">
        <FormControlLabel
          label={`${allChecked() ? "Hide" : "Show"} All`}
          control={
            <Checkbox
              checked={allChecked()}
              indeterminate={someChecked()}
              onChange={() => {
                if (allChecked()) {
                  setMetaExpanded(false);
                  setConstsExpanded(false);
                  setVarsExpanded(false);
                  setM3ApisExpanded(false);
                  setSeqsExpanded(false);
                } else {
                  setMetaExpanded(true);
                  setConstsExpanded(true);
                  setVarsExpanded(true);
                  setM3ApisExpanded(true);
                  setSeqsExpanded(true);
                }
              }}
            />
          }
        />
      </Box>

      <Typography component="h1" variant="h6">
          {map.Meta.Name}
      </Typography>

      <ExpandingSection
        expanded={metaExpanded}
        onChange={setMetaExpanded}
        title="Meta"
      >
        <Meta data={map.Meta} />
      </ExpandingSection>

      {map.M3APIs && map.M3APIs.length > 0 && (
        <ExpandingSection
          expanded={m3ApisExpanded}
          onChange={setM3ApisExpanded}
          title="M3APIs"
        >
          <M3APIs data={map.M3APIs} />
        </ExpandingSection>
      )}
      {map.Constants && map.Constants.length > 0 && (
        <ExpandingSection
          expanded={constsExpanded}
          onChange={setConstsExpanded}
          title="Constants"
        >
          <Constants data={map.Constants} />
        </ExpandingSection>
      )}
      {map.Variables && map.Variables.length > 0 && (
        <ExpandingSection
          expanded={varsExpanded}
          onChange={setVarsExpanded}
          title="Variables"
        >
          <Variables data={map.Variables} />
        </ExpandingSection>
      )}

      <ExpandingSection
        title="Mapping Sequence"
        expanded={seqsExpanded}
        onChange={setSeqsExpanded}
      >
        {map.Sequences &&
          map.Sequences.length > 0 &&
          map.Sequences.map((s) => (
            <Sequence key={s.Type + s.ID} sequence={s} />
          ))}
      </ExpandingSection>
    </>
  );
};

export default FormattedOutput;

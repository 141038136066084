import * as React from "react";
import { useNavigate } from "react-router-dom";

import UploadFile from "../components/UploadFile";
import { MapContext } from "../context/MapContext";
import MapParserService from "../services/MapParserService";
import uploadHandler from "../services/uploadHandler";

interface UploadPageProps {}

const UploadPage: React.FC<UploadPageProps> = () => {
  const {setMap} = React.useContext(MapContext)
  const navigate = useNavigate()
  React.useEffect(() => {
    document.title = 'MapDoc'
  }, [])
  return (
    <>
      <UploadFile
        label="Upload a .zap export file"
        onLoad={async (file: any) => {
          const data = await uploadHandler(file);
          const map = MapParserService.parse(data)
          setMap(map)
          navigate('/output')
        }}
        accept={{
          "application/x-zip": [".zap"],
        }}
      />
    </>
  );
};

export default UploadPage;

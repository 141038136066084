import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import * as React from "react";

import { IVariable } from "../../types/Map";

interface VariablesProps {
  data: IVariable[];
}

const Variables: React.FC<VariablesProps> = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Initial value</TableCell>
            <TableCell>Links Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((v) => (
            <>
              <TableRow key={v.ID}>
                <TableCell>{v.Name}</TableCell>
                <TableCell sx={{ overflowWrap: "anywhere" }}>
                  {v.Type}
                </TableCell>
                <TableCell sx={{ overflowWrap: "anywhere" }}>
                  {v.Description}
                </TableCell>
                <TableCell sx={{ overflowWrap: "anywhere" }}>
                  {v.InitialValue}
                </TableCell>
                <TableCell>{v.LinksCount}</TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Variables;

import Encoding from "encoding-japanese";
import { XMLParser } from "fast-xml-parser";
import JSZip from "jszip";

export default async function handler(file: File): Promise<any> {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const data = e.target?.result as ArrayBuffer;
        const jszip = new JSZip();
        const zip = await jszip.loadAsync(data);
        var mapFileName = Object.keys(zip.files).filter((file) =>
          file.endsWith(".map")
        )[0];
        const file = zip.file(mapFileName);

        if (file) {
          const content = await file.async("arraybuffer");
          var codes = new Uint8Array(content);
          var encoding = Encoding.detect(codes);
          var xml = Encoding.convert(codes, {
            to: "UTF8",
            from: encoding as Encoding.Encoding,
            type: "string",
          });
          const data = new XMLParser({
            ignoreAttributes: false,
            attributeNamePrefix: "_",
            cdataPropName: "_cdata",
            textNodeName: "_text",
          }).parse(xml);

          res(data);
        }
      } catch (err) {
        rej(err);
      }
    };
    reader.onerror = (e) => {
      rej(e);
    };
    reader.readAsArrayBuffer(file);
  });
}

import { Box, Typography } from "@mui/material";
import * as React from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

const Error: React.FC = () => {
  const error = useRouteError();
  let message = "";

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      message = "This page doesn't exist!";
    }

    if (error.status === 401) {
      message = "You aren't authorized to see this";
    }

    if (error.status === 503) {
      message = "Looks like our API is down</div>";
    }

    if (error.status === 418) {
      message = "🫖";
    }
  } else {
    message = "Something went wrong";
  }

  return (
    <Box>
      <Typography>{message}</Typography>
      <pre>{JSON.stringify(error, null, 2)}</pre>
    </Box>
  );
};

export default Error;

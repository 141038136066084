import Box from "@mui/material/Box/Box";
import Stack from "@mui/material/Stack/Stack";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import dayjs from "dayjs";
import * as React from "react";
import MarkdownRenderService from "../services/MarkdownRenderService";
import { IMap } from "../types/Map";
import ViewSelect from "./ViewSelect";
import CopyButton from "./buttons/CopyButton";
import DownloadButton from "./buttons/DownloadButton";
import PrintButton from "./buttons/PrintButton";

interface OutputToolbarProps {
  map: IMap;
  view: string;
  setView: (view: string) => void;
}

const OutputToolbar: React.FunctionComponent<OutputToolbarProps> = ({
  map,
  view,
  setView,
}) => {
  let title = map.Meta.Name;
  let filename = title;
  let content = "";
  if (view === "md") {
    content = MarkdownRenderService.render(map);
    filename += ".md";
  } else if (view === "json") {
    content = JSON.stringify(map, null, 2);
    filename += ".json";
  }

  return (
    <Toolbar>
      <Box sx={{ flexGrow: 1 }}>
        <ViewSelect value={view} setValue={setView} />
      </Box>
      <Stack direction="row" spacing={2}>
        {(view === "md" || view === "json") && (
          <>
            <CopyButton content={content} />
            <DownloadButton content={content} fileName={filename} />
          </>
        )}
        <PrintButton />
      </Stack>
    </Toolbar>
  );
};

export default OutputToolbar;

import * as React from "react";

import PrintHeader from "./PrintHeader";

import './print.css';

const PrintPage: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <>
      
      {children}
    </>
  );
};

export default PrintPage;

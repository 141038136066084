import { Box } from "@mui/material";
import * as React from "react";
import { ILink } from "../../types/Map";

interface ParametersProps {
  links: ILink[];
}

const Parameters: React.FC<ParametersProps> = ({ links }) => {
  const direction = links[0].Direction;
  const getParent = (value: string) => {
    return format(value.substring(0, value.lastIndexOf("/")));
  };
  const getElement = (value: string) => {
    return format(value.substring(value.lastIndexOf("/"), value.length));
  };
  const format = (value: string) => {
    let formatted = value;
    formatted = formatted
      .split("/")
      .map((v) => v + String.fromCharCode(8203))
      .join("/");
    formatted = formatted
      .split("_")
      .map((v) => v + String.fromCharCode(8203))
      .join("_");
    formatted = formatted
      .split("[")
      .map((v) => v + String.fromCharCode(8203))
      .join("[");
    return formatted;
  };
  const inbound = direction === "In";

  return (
    <Box
      sx={{
        float: direction === "In" ? "right" : "left",
        fontSize: "13px",
        lineHeight: "10px",
      }}
    >
      <table className={"parameters-" + direction}>
        <tbody>
          {links.map((l) => (
            <tr key={l.SequenceID + l.From.ID + l.To.ID}>
              <td style={{textAlign: inbound ? 'right' : 'left'}}>
                {l.LinkType.includes("M") && (
                  <>
                    <div style={{ fontWeight: inbound ? "bold" : "" }}>
                      {getElement(l.From.Name)}
                    </div>
                    <div>{getParent(l.From.Name)}</div>
                  </>
                )}
                {!l.LinkType.includes("M") && (
                  <div style={{ fontWeight: inbound ? "bold" : "" }}>
                    {format(l.From.Name)}
                  </div>
                )}
              </td>
              <td>&rarr;</td>
              <td>
                {l.LinkType.includes("M") && (
                  <>
                    <div style={{ fontWeight: !inbound ? "bold" : "" }}>
                      {getElement(l.To.Name)}
                    </div>
                    <div>{getParent(l.To.Name)}</div>
                  </>
                )}
                {!l.LinkType.includes("M") && (
                  <div style={{ fontWeight: !inbound ? "bold" : "" }}>
                    {format(l.To.Name)}
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

export default Parameters;

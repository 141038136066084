import { ArrowBack } from "@mui/icons-material";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { MapContext } from "../context/MapContext";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const navigate = useNavigate();
  const { setMap } = React.useContext(MapContext);

  const isHome = useMatch("/");
  

  return (
    <AppBar position="static">
      <Toolbar>
        {!isHome && (
          <IconButton
            color="inherit"
            onClick={() => {
              setMap(null);
              navigate("/");
            }}
          >
            <ArrowBack />
          </IconButton>
        )}

        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          MapDoc
        </Typography>

      </Toolbar>
    </AppBar>
  );
};

export default Header;

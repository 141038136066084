import dayjs from "dayjs";

import { IMap } from "../types/Map";

export default class MarkdownRenderService {
  public static render(map: IMap): string {
    let md: string[] = [];
    md.push(`# ${map.Meta.Name}`);
    md.push("");
    md.push("## Mapping Meta");
    md.push("");
    md.push(`Description: ${map.Meta.Description}`);
    md.push("");
    md.push(`File: ${map.Meta.File}`);
    md.push("");
    md.push(
      `Change date: ${dayjs(new Date(Number(map.Meta.ChangeDate))).format(
        "YYYY-MM-DD HH:MM:ss"
      )}`
    );
    md.push("");
    md.push(`Version: ${map.Meta?.Version ?? ""}`);
    md.push("");
    md.push("");
    md.push("## Schema In");
    md.push("");
    md.push(`File: ${map.SchemaIn.File ?? ""}`);
    md.push("");
    md.push("");

    if (map.SchemaOut) {
      md.push("## Schema Out");
      md.push("");
      md.push(`File: ${map.SchemaOut.File ?? ""}`);
      md.push("");
      md.push("");
    }

    if (map.M3APIs && map.M3APIs.length > 0) {
      md.push("## M3APIs");
      md.push("");
      md.push("|Program|Transaction|Links Count|");
      md.push("|:-|:-|-:|");
      for (let a of map.M3APIs) {
        md.push(`|${a.Program}|${a.Transaction}|${a.LinksCount}|`);
      }
      md.push("");
    }

    if (map.Constants && map.Constants.length > 0) {
      md.push("## Constants");
      md.push("");
      md.push("|Name|Value|Type|Description|Links Count|");
      md.push("|:-|:-|:-|:-|-:|");
      for (let c of map.Constants) {
        md.push(
          `|${c.Name}|${c.Value}|${c.Type}|${c.Description}|${c.LinksCount}|`
        );
      }
      md.push("");
    }

    if (map.Variables && map.Variables.length > 0) {
      md.push("## Variables");
      md.push("");
      md.push("|Name|Initial Value|Type|Description|Links Count|");
      md.push("|:-|:-|:-|:-|-:|");
      for (let v of map.Variables) {
        md.push(
          `|${v.Name}|${v.InitialValue}|${v.Type}|${v.Description}|${v.LinksCount}|`
        );
      }
      md.push("");
    }

    if (map.Sequences && map.Sequences.length > 0) {
      md.push("## Mapping");
      md.push("");
      for (let s of map.Sequences) {
        let mdSeq = [];
        const indent = ">".repeat(s.Level);
        if (s.Type === "LB") {
          mdSeq.push(`### ${s.Name} (_${s.ConditionDescription}_)`);
          mdSeq.push("");
        } else if (s.Type === "LE") {
          mdSeq.push(`_End ${s.Name}_`);
          mdSeq.push("");
        } else if (s.Type.startsWith("A")) {
          mdSeq.push(`### ${s.Name} (${s.TypeDescription})`);
          mdSeq.push("");
        } else {
          mdSeq.push(`### ${s.Name} (${s.TypeDescription})`);
          mdSeq.push("");
        }

        if (s.Description) {
          mdSeq.push(`_${s.Description}_`);
        }

        if (s.M3API) {
          mdSeq.push("#### M3API");
          mdSeq.push("");
          mdSeq.push(`${s.M3API.Path}`);
          mdSeq.push("");
        }

        if (s.InputLinks && s.InputLinks.length > 0) {
          mdSeq.push(`#### Input`);
          for (let i of s.InputLinks) {
            let line = ` * ${i.From.Name.trim()}`;
            if (!i.LinkType.includes("M") && i.From.Description) {
              line += ` _${i.From.Description}_`;
            }
            line += ` &rarr; ${i.To.Name.trim()}`;
            mdSeq.push(line);
          }
          mdSeq.push("");
        }

        if (s.Code) {
          mdSeq.push("```");
          mdSeq.push("");
          mdSeq.push(...s.Code.split("\n"));
          mdSeq.push("");
          mdSeq.push("```");
          mdSeq.push("");
        }

        if (s.OutputLinks && s.OutputLinks.length > 0) {
          mdSeq.push(`#### Output`);
          for (let o of s.OutputLinks) {
            let line = ` * ${o.From.Name.trim()}`;
            line += ` &rarr; ${o.To.Name.trim()}`;
            if (!o.LinkType.includes("M") && o.To.Description) {
              line += ` _${o.To.Description}_`;
            }
            mdSeq.push(line);
          }
          mdSeq.push("");
        }

        mdSeq.push("");
        mdSeq = mdSeq.map((s) => indent + " " + s);
        md = [...md, ...mdSeq];
      }
    }

    return md.join("\r\n");
  }
}

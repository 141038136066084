import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import * as React from "react";

import { IM3API } from "../../types/Map";

interface M3ApisProps {
  data: IM3API[];
}

const M3APIs: React.FC<M3ApisProps> = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: "650px" }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Program</TableCell>
            <TableCell>Tranasction</TableCell>
            <TableCell>Usage Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((a) => (
            <>
              <TableRow key={a.Path}>
                <TableCell>{a.Program}</TableCell>
                <TableCell>{a.Transaction}</TableCell>
                <TableCell>{a.LinksCount}</TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default M3APIs;

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import * as React from "react";

import { IConstant } from "../../types/Map";

interface ConstantsProps {
  data: IConstant[];
}

const Constants: React.FC<ConstantsProps> = ({ data }) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Links Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((c) => (
            <TableRow key={c.ID}>
              <TableCell>{c.Name}</TableCell>
              <TableCell sx={{ overflowWrap: "anywhere" }}>{c.Type}</TableCell>
              <TableCell sx={{ overflowWrap: "anywhere" }}>
                {c.Description}
              </TableCell>
              <TableCell sx={{ overflowWrap: "anywhere" }}>{c.Value}</TableCell>
              <TableCell>{c.LinksCount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Constants;

import { Box, Typography } from "@mui/material";
import * as React from "react";

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <>
      
      <Box id="footer" marginTop={2}>
        <Typography
          variant="subtitle1"
          component="div"
          align="center"
          sx={{ flexGrow: 1 }}
        >
          &copy; {new Date().getFullYear()} Designed Results
        </Typography>
      </Box>
    </>
  );
};

export default Footer;
